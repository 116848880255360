import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { BASE_DOCUMENT } from "../config";
import { get_project_details_url } from "../config";
import { useParams } from "react-router-dom";
import { API_HEADER } from "../../src/config";

const ClientSiteVisit = (props) => {
  const { id } = useParams();
  const [auditPlanName, setAuditPlanName] = useState(null);
  const [auditPlan, setAuditPlan] = useState("");
  const [auditFindingName, setAuditFindingName] = useState(null);
  const [auditFinding, setAuditFinding] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );
      const { record } = response.data;

      let url1 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_plan}`;
      setAuditPlanName(url1);
      setAuditPlan(record.site_audit_plan);

      let url2 = `${BASE_DOCUMENT}/projects/${record.id}/${record.site_audit_finding}`;
      setAuditFindingName(url2);
      setAuditFinding(record.site_audit_finding);

    } catch (error) {}
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return (
    <>
      <form
        method="post"
        encType="json/form-data"
      >
        <table className="table table-bordered  table-hover">
          <thead>
            <tr>
              <th className="text-center lightgreen p-3 fw-bolder fs-6 table-heading-color">
                S.No
              </th>
              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Document Name
              </th>

              <th className="text-center lightgreen p-3 fw-bolder fs-6  table-heading-color">
                Uploaded Document
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>
              <td className="text-center lightgreen p-3">
                Audit Plan
                <span style={{ color: "red" }}>*</span>
              </td>

              <td>
                <a href={auditPlanName} target="_blank">
                  {auditPlan}
                </a>
              </td>
            </tr>
            <tr>
              <td class="text-center">2</td>

              <td className="text-center lightgreen p-3">Audit Finding</td>

              <td>
                <a href={auditFindingName} target="_blank">
                  {auditFinding}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        {loading && <div className="loader"></div>}
      </form>
    </>
  );
};

export default ClientSiteVisit;
