import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faArrowRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {ReactComponent as BarsIcon} from '../../src/assets/bars-solid.svg'
import axios from "axios";
import { toast } from "react-toastify";
import { API_HEADER, autologin_url, getDashboardData, mail_reminder_url } from '../config';
import NotificationTile from "../utlis/NotificationTile";


const Header = () => {

  let [username, SetUserName] = useState("");
  const [designationName, setDesignationName] = useState()
  const [roleData, setRolesData] = useState([]);

  const navigate = useNavigate();
  const designation_id = sessionStorage.getItem("designation_id");
  const user = sessionStorage.getItem("user");
  

  const parent_id = JSON.parse(user).parent_id;
  const multi_user = JSON.parse(user).multi_user;

  useEffect(function () {
    const name = sessionStorage.getItem("name");
    SetUserName(name);
    if(designation_id == '1'){
      setDesignationName("")
    }
    else if(designation_id == '2'){
      setDesignationName("MD")
    }
    else if(designation_id == '3'){
      setDesignationName("Technical Manager")
    }
    else if(designation_id == '4'){
      setDesignationName("Technical Manager Support")
    }
    else if(designation_id == '5'){
      setDesignationName("Sales Team")
    }
    else if(designation_id == '6'){
      setDesignationName("Proposal Team")
    }
    else if(designation_id == '7'){
      setDesignationName("Technical Reviewer")
    }
    else if(designation_id == '8'){
      setDesignationName("Assessment Team")
    }
    else if(designation_id == '9'){
      setDesignationName("SBU")
    }
    else if(designation_id == '11'){
      setDesignationName("Registrar")
    }
    else if(designation_id == '13'){
      setDesignationName("Client")
    }
  else {
    setDesignationName('')
  }
  }, []);
  

  const loginRoleWise = async () => {
    
    try {

        let payload={
            id : parent_id
        }

        const result = await axios.post(autologin_url, payload,API_HEADER);
        
        if(result.status===200 && result.data.status===true){
            
            toast.success('Logout Successfully');
            const mail_reminder = await axios.get(`${mail_reminder_url}`);
            localStorage.setItem('mail_reminder', JSON.stringify(mail_reminder.data.data));
            sessionStorage.setItem("token", result.data.user.token);
            sessionStorage.setItem("designation_id", Number(result.data.user.designation_id));
            sessionStorage.setItem("name", result.data.user.name);
            sessionStorage.setItem('user', JSON.stringify(result.data.user));
            sessionStorage.setItem("multi_user", result.data.user.multi_user);

            navigate('/dashboard')
            window.location.reload()

        }
        else{
            toast.error(result.data.error);
        }   
    } catch (error) {
        toast.error('Invalid User');
    }

  };

  const logout = () => {
    sessionStorage.clear();
    {
      designation_id == 13 ? navigate("/clientlogin") : navigate("/login");
    }
  };

  const getDashboard = async () => {
    try {
      const result = await axios.get(`${getDashboardData}`, API_HEADER);
      const dashboard = result.data.roles;

      setRolesData(dashboard);

    } catch (error) {
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);


  return (
    <>
      <nav className="main-header navbar navbar-expand d-flex align-items-center sidenavbar_bg">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link text-white"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
             
              <BarsIcon style={{width:'25px',height:'25px', marginRight: "5px"}}/>
            </a>
          </li>
        </ul>

       
        <ul className="navbar-nav ml-auto ">


           {
            multi_user == 0?

              <>
              
            <li class="dropdown dropdown-list-toggle">     
            <NotificationTile/>
            </li>
              </>:
              ''

            }
            
          {
            multi_user == 0 && designation_id != 13?

            <>


            <li className="user-panel d-flex nav-item mx-lg-3 mx-0 align-items-center ">
            <button type="button" class="btn btn-outline-success" onClick={() => loginRoleWise()}>
             Change Role
            </button>
            </li>

            </>
            :
           ''
          }
       
          <li className="user-panel d-flex nav-item mx-lg-1 mx-0 align-items-center ">
            
            
            <Avatar
              size={{ xs: 16, sm: 24, md: 32, lg: 32, xl: 40, xxl: 40 }}
              icon={<UserOutlined />}
              className="d-lg-block d-md-none d-none bg-secondary"
            />
            
            <div
              className="info"
              style={{ lineHeight: designation_id !== 13 ? "0.4" : "base" }}
            >
              <p className="text-black">{username}</p>
              
              
               <p className="text-dark bg-white p-1 text-sm rounded-2 mb-0" >
                {designationName}
              </p>
              
              
            </div>
          </li>
          

          <li className="nav-item ">
            <Tooltip title="logout" color="grey">
              <button className="nav-link text-black" onClick={() => logout()}>
                <FontAwesomeIcon
                  className="nav-icon"
                  icon={faArrowRightFromBracket}
                />
              </button>
            </Tooltip>
          </li>

          <li className="nav-item ">
            <a
              className="nav-link text-black"
              data-widget="fullscreen"
              href="#"
              role="button"
            >
              <i className="fas fa-expand-arrows-alt" />
            </a>
          </li>
        </ul>
      </nav>
      
    </>
  );
};

export default Header;
