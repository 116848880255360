import React, { useState, useEffect } from "react";
import "../../src/App.css";
import { useNavigate } from "react-router-dom";
import ProgramWiseProjects from "../Components/Graphs/projectDashboardGraphs/ProgramWiseProject";
import ProjectStageWise from "../Components/Graphs/projectDashboardGraphs/ProjectStageWise";
import SBUWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/SBUWorkloadGraph";
import TLWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/TLWorkloadGraph";
import ValidatorWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/ValidatorWorkloadGraph";
import TraineeWorkloadGraph from "../Components/Graphs/projectDashboardGraphs/TraineeWorkloadGraph";
import RegionWiseProjectGraph from "../Components/Graphs/projectDashboardGraphs/RegionWiseProjectGraph";
import ContractSignedMonthlyGraph from "../Components/Graphs/projectDashboardGraphs/ContractSignedMonthlyGraph";
import KickoutMontlyWiseGraph from "../Components/Graphs/projectDashboardGraphs/KickoutMonthlyWiseGraph";
import ProjectMovingDateWise from "../Components/Graphs/projectDashboardGraphs/ProjectMovingDateWise";
import { DatePicker, Spin } from "antd";
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import ProjectStageBar from "../Components/Graphs/projectDashboardGraphs/ProjectStageBar";
import RFIMonthly from "../Components/Graphs/projectDashboardGraphs/RFIMonthly";
import KickoutRFI from "../Components/Graphs/projectDashboardGraphs/KickoutRFI";
import { API_HEADER, getStatsData } from "../config";
import axios from "axios";

const ProjectDashboard = () => {

  const dateFormat = "DD/MM/YYYY";

  const [totalProject,setTotalProject]=useState(0);

  useEffect( ()=>{

    const getProposalScopeWise= async()=>{

      const payload = { "fy": "" };
      const reqData= await axios.post(`${getStatsData}`, payload,API_HEADER);
      setTotalProject(reqData.data.total_count);

    }

    getProposalScopeWise();

  },[]);

  const navigate = useNavigate()
  const handleClick = () => {
    navigate("/dashboard");
  };

  const [search,setSearch]=useState(false);

  const [startDate,setstartDate]=useState(null);
  const [endDate,setendDate]=useState(null);
  const [loader,setLoader]=useState(false);

  const handleSearchByColumn = (value) => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        alert("Start Date cannot be after End Date.");
        setendDate(null);
        setstartDate(null);
        return;
      }
    }
    setSearch(!search);
  };  

  const resetFilters = () => {
    setendDate(null);
    setstartDate(null);
    setSearch(!search);
  }; 

  const handleDownload = async () => {
    setLoader(true)

    const pdf = new jsPDF();
    const sections = document.querySelectorAll('.page-break'); // Use class to target sections

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const canvas = await html2canvas(section);
      const imgData = canvas.toDataURL('image/png');
      
      if (i > 0) {
        pdf.addPage();
      }
      
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 297); // Adjust dimensions as needed
    }
    
    pdf.save('document.pdf');
    setLoader(false)

  };

  const navigateToAllProjects = async () => {
    navigate("/totalprojectsat");
  };

  return (
    <>
  
      <Spin spinning={loader}>
      <div className="content dashboardcolor">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12 my-4">


<div className="my-3 mx-4 d-flex align-items-center justify-content-between">
  <h3 className="font-weight-bold">Project Dashboard</h3>

  <div className="d-flex">
    <button className="mx-2 btn btn-light" onClick={navigateToAllProjects}>Total Project : {totalProject}</button>
    
    <button
      type="button"
      className="mx-2 btn btn-outline-success"
      onClick={handleClick}
    >
      Home
    </button>  

    {/* <button className="mx-2 btn btn-primary" onClick={handleDownload}>Download</button> */}
  </div>
</div>
  

            </div>
          </div>

    
          <div className="row mx-4">

            <div className="col-sm-3 col-md-2">
            <div className="mb-3">
               <label className="text-capitalize textcolumntitle  fw-bold font12px">
                   Start Date
               </label>
                 <DatePicker
                      onChange={(date, dateString) => setstartDate(date)}
                      placeholder="From Date"
                      style={{ width: "100%" }}
                      className="rounded-2"
                      format={dateFormat}
                      showTime={false}
                      value={startDate}
                      disabledDate={(current) => current && current > moment().endOf('day')}
                 />
            </div>
            
            </div>

              <div className="col-sm-3 col-md-2">
              <div className="mb-3">
                <label className="text-capitalize textcolumntitle  fw-bold font12px">
                  End Date
                </label>
              <DatePicker
                   onChange={(date, dateString) => setendDate(date)}
                   placeholder="To Date"
                   style={{ width: "100%" }}
                   className="rounded-2"
                   format={dateFormat}
                   showTime={false}
                   value={endDate}
                   disabledDate={(current) => current && current > moment().endOf('day')}
              />
              </div>
              
              </div>

              <div className="col-sm-3 col-md-2 mt-4">

                        <button
                          className="btn btn-success btn-md rounded-2 me-2"
                          onClick={handleSearchByColumn}
                        >
                          Search
                        </button>

                        <button
                          className="btn btn-danger btn-md rounded-2"
                          onClick={resetFilters}
                        >
                          Reset
                        </button>

              </div>

              <div className="col-sm-3 col-md-6 mt-4 justify-content-end">

                     <h4 style={{float:"right"}}><b>FY 2024-2025</b></h4>

              </div>

          </div>
 
        
          <div className="page-break">

          <div className="row mx-4 graph">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12  my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Program Wise Projects</h4>
                 <ProgramWiseProjects search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4 graph">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-4 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Projects in Different Stages</h4>
                    <ProjectStageWise search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>



          <div className="row mx-4 graph">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-2 ">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>SBU Head WorkLoad</h4>
                  <SBUWorkloadGraph search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          
            <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Team Leader WorkLoad</h4>
                  <TLWorkloadGraph search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Validator/Verifier Workload</h4>
                  <ValidatorWorkloadGraph search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Trainee Workload</h4>
                  <TraineeWorkloadGraph search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>

          </div>

          
          <div className="page-break">

          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12 col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Region Wise Projects</h4>
                  <RegionWiseProjectGraph search={search} startDate={startDate} endDate={endDate}/>
                </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className="col-8 col-md-12 col-lg-8  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Kickout Month Wise</h4>
                    <KickoutMontlyWiseGraph/>
                  </div>
              </div>
            </div>
          
            <div className="col-4 col-md-12 col-lg-4  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                  <h4>Project Delayed</h4>
                    <ProjectMovingDateWise/>
                  </div>
              </div>
            </div>
          </div>


          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                <h4>Contracts Signed vs Delivered</h4>
                 <ContractSignedMonthlyGraph />
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                <h4>Project Stages</h4>
                 <ProjectStageBar />
                </div>
              </div>
            </div>
          </div>
          
          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                <h4>RFI Initial Submission</h4>
                 <RFIMonthly />
                </div>
              </div>
            </div>
          </div>

          <div className="row mx-4">
            <div className="col-12 col-md-12 col-lg-12  col-sm-12 my-2 graph">
              <div class="mycard_dash">
                <div class="mycard-content text-center">
                <h4>RFI  Resubmission</h4>
                 <KickoutRFI />
                </div>
              </div>
            </div>
          </div>

          </div>

        </div>
      </div>
      </Spin>
    </>
  );
};

export default ProjectDashboard;
