import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Tooltip as Tip } from "antd";
import { Table, Tag, Select } from "antd";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_HEADER } from "../../config";
import Header from "../../Pages/Header";
import Footer from "../Footer";
import SideNavbar from "../SideNavbar";
import { getAllProposalDetails } from "../../config";

const ProposalTable = () => {
	const [dataSource, setDataSource] = useState([]);
	const [loadData, setloadData] = useState(false);
	const [loader, setLoader] = useState(false);

    const location = useLocation();
    const {contract_signed_status,contract_signed_month,counter_status,title,program, program_status,program_id,month_year_created}=location.state;

	const navigate = useNavigate();

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});

	const handleTableChange = (pagination, filters, sorter) => {
		setPagination(pagination);
		setloadData(true);
	};

	
	const editForm = async (record) => {
		navigate(`/atdeskreview/${record.id}`)
	}

	const columns = [
	    {
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					S.No
				</span>
			),
			dataIndex: "proposal_id",
			fixed: "left",
			width: 70,
			render: (text, record, index) => {
				const pageIndex = (pagination.current - 1) * pagination.pageSize;
				return pageIndex + index + 1;
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					EID
				</span>
			),
			fixed: "left",
			width: 90,
			render: (text, record) => {
				return (
					<Tip title={record.earthood_id}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.earthood_id}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.earthood_id > record2.earthood_id) ? 1 : (record1.earthood_id === record2.earthood_id) ? 0 : -1
			},
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Project Name
				</span>
			),
			width: 180,
			render: (text, record) => {
				return (
					<Tip title={record.project_name}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.project_name}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.project_name > record2.project_name) ? 1 : (record1.project_name === record2.project_name) ? 0 : -1
			}
		},		
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Program
				</span>
			),
			width: 100,
			render: (text, record) => {
				return (
					<Tip title={record.program_name}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.program_name}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.program_name > record2.program_name) ? 1 : (record1.program_name === record2.program_name) ? 0 : -1
			}
		},
        {
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Implementation Fees
				</span>
			),
			width: 100,
			render: (text, record) => {
				return (
					<Tip title={record.implemented_fees}>
						<span className="text-capitalize textcolorgreen font14px preserve-space text-ellipsis">
							{record.implemented_fees}
						</span>
					</Tip>
				);
			},
			sorter: (record1, record2) => {
				return (record1.implemented_fees > record2.implemented_fees) ? 1 : (record1.implemented_fees === record2.implemented_fees) ? 0 : -1
			}
		},
		{
			title: (
				<span className="text-capitalize textcolumntitle font14px fw-bold">
					Action
				</span>
			),
			dataIndex: "",
			key: "x",
			fixed: "right",
			width: 90,
			render: (record) => (
				<a className="">

					
							<EyeOutlined
								onClick={() => editForm(record)}
								style={{ color: "blue", fontSize: '20px' }}
							/>
						
				</a>
			),
		},
	];

	const allData = async () => {
		try {
			setLoader(true)
			let payload = {
				counter_status: counter_status,
				page: pagination.current,
				limit: pagination.pageSize,
				program : program,
			    program_status : program_status,
				month_year_created:month_year_created,
				contract_signed_status:contract_signed_status,
                contract_signed_month:contract_signed_month
			};

			const response = await axios.post(
				`${getAllProposalDetails}`,
				payload,
				API_HEADER,
			);
			setDataSource(response.data.records.data);
			setloadData(false);

			setPagination((prevPagination) => ({
				...prevPagination,
				total: response.data.records.total,
			}));
			setLoader(false)

		} catch (error) {
		}
	};

	useEffect(() => {
		allData();
	}, [loadData]);

	return (
		<>
			<Header />
			<SideNavbar />
			<div className="content-wrapper bg-white">
				<div className="content">
					<div className="container-fluid">
						<div className="row"
							style={{
								justifyContent: "center",
							}}
						>
							
							<div className="">

								<div className="mx-3 my-3 border-1 border border-light-subtle p-0 rounded-3 mt-5">

								<div className="row border-0 ">
										<div className="col-12 ">
											<p className="text-black text-capitalize  font20px fw-bold p-3 rounded-top-3">
											{title}
											</p>
										</div>
									</div>

									<div className="row my-3 mx-3">
										<Table
										    className="border-1 border ronded-0 border-subtle-light px-0"
											columns={columns}
											dataSource={dataSource}
											rowKey="proposal_id"
											pagination={pagination}
											onChange={handleTableChange}
											loading={loader}
											tableLayout="fixed"
										/>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer/>
		</>
	);
};

export default ProposalTable;
