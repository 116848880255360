import React ,{ useState, useEffect} from "react";
import  Chart  from "react-apexcharts";
import { API_HEADER,project_moving_datewise_url } from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ProjectMovingDateWise()
{
    const navigate = useNavigate();

   const [updatedPeriod, setUpdatedPeriod]= useState([]);
   const [projectCount, setProjectCount]= useState([]);
   const [programWiseData, setProgramWiseData] = useState([]);

   useEffect( ()=>{
       const updatedperiod=[];
       const projectcount=[];

       const getProposalScopeWise= async()=>{
       const payload = { "fy": "" };
       const reqData= await axios.post(`${project_moving_datewise_url}`, payload,API_HEADER);
   
       const MyprogramwiseData = Object.values(reqData.data.data);
     
       const order = ['0-10 days', '11-20 days', '21-30 days', 'older'];

       MyprogramwiseData.sort((a, b) => order.indexOf(a.updated_period) - order.indexOf(b.updated_period));

      for (let key in MyprogramwiseData) {
        if (MyprogramwiseData.hasOwnProperty(key)) {
          updatedperiod.push(MyprogramwiseData[key].updated_period);
          projectcount.push(MyprogramwiseData[key].project_count);
        }
      }
      
       setUpdatedPeriod(updatedperiod);
       setProjectCount(projectcount);
       setProgramWiseData(MyprogramwiseData);
 
       }

       getProposalScopeWise();

   },[]);
   
   const handleChartClick = (event, chartContext, { seriesIndex, dataPointIndex, w }) => {

    const seriesName = w.globals.seriesNames[seriesIndex];

    const programName = w.globals.labels[dataPointIndex];

    const matchedProgram = programWiseData.find((program) => program.updated_period === programName);

    const updated_period = matchedProgram ? matchedProgram.updated_period : null;

    const title=updated_period;

    if (updated_period) {
      navigate('/programwiseprojecttable', { state: {title, updated_period,seriesName:programName} });    
    }
  };

   return(
    <React.Fragment>
        <div className="container-fluid">
           
            <Chart
            type="bar"
            width={"100%"}
            height={400}
            series={[
                {
                    name:"Project Count",
                    data: projectCount,
                    color: '#E74C3C'
                },
                // {
                //     name:"Project Completed",
                //     // data: projectCompleted,
                //     color: '#27AE60'
                // },
                
            ]}

            options={{
                // title:{
                //     text:"Proposal Month Wise"
                // },
                chart:{
                    toolbar: {
                        show: false,  // This will hide the icons on the top right corner
                      },
                    stacked:true,
                    events: {
                        dataPointSelection: handleChartClick,
                      },
                },
                plotOptions:{
                    bar:{
                        horizontal:false,
                        columnWidth:'40%',
                        dataLabels : {
                            total: {
                                enabled: true,
                                style: {
                                  fontSize: '11px',
                                  fontWeight: 900
                                },
                                
                              }
                          }
                      }
                },
                stroke: {
                    width: 1,
                },
                xaxis:{
                    title:{
                        text:"Project not moving forward",
                        style: {
                            fontSize: '16px',
                            fontWeight: 'bold'
                        }
                    },
                    categories : updatedPeriod
                },
                yaxis:{
                    title:{
                        text:"Number of Projects",
                        style:{fontSize:'16px',fontWeight:'bold'}
                    },
                    labels:{
                        style: { fontSize: "15"},

                    }
                },
                legend:{
                    position: 'bottom',
                },
                dataLabels:{
                    enabled:false,
                },
                grid: {
                    show:true,
                    xaxis:{
                        lines:{
                            show:false
                        }
                    },
                    yaxis:{
                        lines:{
                            show:false
                        }
                    }

                },
                grid: {
                    borderColor: '#f1f1f1',
                    row: {
                      colors: ['transparent'], 
                      opacity: 1,
      
                    },
                  },

            }}

            />
        </div>
    </React.Fragment>
);
}
export default ProjectMovingDateWise;