import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Tabs,
  Select,
  Upload,
  message,
  Input,
  Alert,
  Spin,
  Modal,
  Form as NewForm 
} from "antd";
import axios from "axios";
import { Tooltip } from 'antd';
import { API_HEADER, BASE_DOCUMENT, get_client_name_url, get_meths_url, get_ta_tr_experts,get_contact_person_url,get_project_team_info } from "../config";
import { get_scope_url } from "../config";
import { get_sectoralscope_url } from "../config";
import { get_program_url } from "../config";
import { get_country_url } from "../config";
import { get_assesment_url } from "../config";
import { toast } from "react-toastify";
import Header from "./Header";
import SideNavbar from "../Components/SideNavbar";
import Footer from "../Components/Footer";
import {
  get_project_details_url,
  edit_assessment_team_url,
  get_scope_pa_poa_url,
} from "../config";
import { get_trsbu_url } from "../config";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { at_desk_review_url } from "../config";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ActivityChain from "../Components/ActivityChain";
import { MultiSelect } from "react-multi-select-component";
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import TeamChangeDisable from "../Components/TeamChange/TeamChangeDisable";
import TeamChangeRequest from "../Components/TeamChange/TeamChangeRequest";
import TeamChangeSummary from "../Components/TeamChange/TeamChangeSummary";
import TMSTeamChange from "../Components/TeamChange/TMSTeamChange";
import TMTeamChange from "../Components/TeamChange/TMTeamChange";
import SBUTeamChange from "../Components/TeamChange/SBUTeamChange";
import TeamChangeDocs from "../Components/TeamChange/TeamChangeDocs";
import TLTeamChange from "../Components/TeamChange/TLTeamChange";
import TLSBUTeamChange from "../Components/TeamChange/TLSBUTeamChange";

const fileTypes = [
  "JPG",
  "JPEG",
  "PDF",
  "RAR",
  "XLS",
  "XLSX",
  "DOC",
  "DOCX",
  "ZIP",
  "XLSM",
];

const TeamChangeModule = () => {
  const { id } = useParams();
  const location = useLocation()
  const teamId = location?.state?.teamId ?? '';

  const navigate = useNavigate();

  const designation_id = sessionStorage.getItem("designation_id");
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const cc_id = userdata.id;

  const [projectid, setProjectId] = useState(null);

  const [clientName, setClientName] = useState([]);
  const [sectoralScope, setSectoralScope] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setformSubmitting] = useState(false);

  const [meth, setMeth] = useState([])
  const [myscope, setMyScope] = useState([]);
  const [program, setProgram] = useState([]);
  const [country, setCountry] = useState([]);
  const [atlist, setAtList] = useState([]);
  const [f23name, setF23Name] = useState(null);
  const [rowCount, setRowCount] = useState(1);
  const [formEdit, setformEdit] = useState(true);
  const [trlist, setTRList] = useState([]);
  const [remark, setRemark] = useState("");
  const [fileTLSizeError, setFileTLSizeError] = useState(false);
  const [file, setFile] = useState(null);
  const [formdisable, setFormDisable] = useState(false);

  const [scope_PA_POA, setScope_PA_POA] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [isTL, setIsTL] = useState(false);
  const [isAT, setIsAT] = useState(false);
  const [isCC, setIsCC] = useState(false);
  const [member, setMember] = useState();
  const [projectstatus, setProjectStatus] = useState(null);
  const [status, SetStatus] = useState(null);
  const [siteVisitStatus, setSiteVisitStatus] = useState("");
  const [role, setRole] = useState("");

  const [mysectorscope, setMySectorScope] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selected, setSelected] = useState([]);

  const [teamLeader, setTeamLeader] = useState("");
  const [taExpert, setTAExpert] = useState("");
  const [validator, setValidator] = useState("");
  const [financeExpert, setFinanceExpert] = useState("");
  const [localExpert, setLocalExpert] = useState("");
  const [methExpert, setMethExpert] = useState("");
  const [traineeExpert, setTraineeExpert] = useState("");
  const [teamChangeStatus, setTeamChangeStatus] = useState(0);

  const [taTrExpert, setTATRExpert] = useState([]);
  const [mytaexpert, setMyTaexpert] = useState([]);
  const [myexperttr, setMyExpertTr] = useState([]);
  const [trExpert, setTRExpert] = useState([]);

  const [clientContactPerson, setClientContactPerson] = useState([]);
  const [monitoringPeriod, setMonitoringPeriod] = useState([]);
  const [weblink, setWeblink] = useState("");
  const [apiData, setAPIData] = useState('')

  const [teamChangeType, setTeamChangeType] = useState('')

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [defaultClientName, setDefaultClientName] = useState(null);
  const [clientStatus, setClientStatus] = useState(null);
  

  const [record, setRecord] = useState("");

  const [f23, setF23] = useState("");

  const [formData, setFormData] = useState({
    project_name: "",
    project_status: "",
    earthood_id: "",
    client_id: "",
    country: "",
    program: "",
    program_id: "",
    implemented_fees: "",
    created_at: "",
    scope: [],
    scope_pa: "",
    sectoral_scope: [],
    team_leader: "",
    ta_expert: "",
    validator_verifier: "",
    finance_expert: "",
    local_expert: "",
    meth : '',
    meth_expert: "",
    trainee_validator: "",
    technical_reviewer: "",
    expert_tr: "",
    sbu_head: "",
    pdd_documents: "",
    er_documents: "",
    mr_documents: "",
    evidence_name: [],
    contact_person_id:'',
    deadline_date: '',

  });

  const [rangeForm] = NewForm.useForm();
  const { RangePicker } = DatePicker;

  const fetchData = async (ids) => {
    try {
      const response = await axios.get(
        `${get_project_details_url}/${ids}`,
        API_HEADER
      );

      if (response && response.data && response.data.record) {
        const { record } = response.data;

        setProjectId(record.id);
        const responseteamleader = await axios.get(
          `${get_assesment_url}?program_id=${record.program}`
        );
        setAPIData(record)
        setAtList(responseteamleader.data.data);
        setSelectedClient(record.client_id);

        setTeamLeader(record.team_leader);
        setTAExpert(record.ta_expert);
        setValidator(record.validator_verifier);
        setFinanceExpert(record.finance_expert);
        setLocalExpert(record.local_expert);
        setMethExpert(record.meth_expert);
        setTraineeExpert(record.trainee_validator);
        // setTeamChangeStatus(record.team_change_status);
        setWeblink(record.weblink)
        setClientStatus(record.client_id);

        setformEdit(true)

        setFormData({
          project_name: record.project_name,
          project_status: record.project_status,
          earthood_id: record.earthood_id,
          client_id: record.client_id,
          country: record.country,
          program: record.program,
          program_id: record.program_id,
          implemented_fees: record.implemented_fees,
          scope: record.scope,
          scope_pa: record.scope_pa,
          sectoral_scope: selected,
          evidence_name: record.evidences || [],
          team_leader: record.team_leader,
          ta_expert: record.ta_expert,
          validator_verifier: record.validator_verifier,
          finance_expert: record.finance_expert,
          local_expert: record.local_expert,
          meth_expert: record.meth_expert,
          trainee_validator: record.trainee_validator,
          technical_reviewer: record.technical_reviewer,
          expert_tr: record.expert_tr,
          sbu_head: record.sbu_head,
          status: record.status,
          contact_person_id: record.contact_person_id,
          deadline_date: record.deadline_date,
          meth: record.meth,
        });

        
        if (record?.monitoring_period) {
          setMonitoringPeriod(JSON.parse(record.monitoring_period))
        }
        
        const isTeamLeader = record.team.some((item) => item.role_id == 1);
        setIsTL(isTeamLeader);

        console.log("team leader",isTeamLeader)
  
        const isTeamMember = record.team.some((item) => item.role_id > 1);
        setIsAT(isTeamMember);
  
        if (designation_id == 8) {
          if (isTeamLeader) {
            setRole("Team Leader");
          } else {
            setRole("Team Member");
          }
        }

        let url3 = `${BASE_DOCUMENT}/documents/${
          record.earthood_id.split(" ")[0]
        }/${record.signed_contract}`;

        setF23Name(url3);
        setF23(record.signed_contract);

        setRowCount(record.evidences ? record.evidences.length : 1);
      

        setProjectStatus(record.project_status);
        SetStatus(record.status);
        setFormDisable(true);

      }
    } catch (error) {}
  };



  const fetchDataTeamChange = async (ids) => {
    try {
    
        const response = await axios.get(
            `${get_project_team_info}/${ids}?team_id=${teamId}`,
            API_HEADER
        );
        setAPIData(response.data.record)
        setFormData({
            program: response.data.record.program
        })

        const sectorscopes = response.data.record.sectoral_scope?.split(",").map(Number);
        const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
            sectorscopes.includes(sectorscope.value)
        );
        setSelectedSector(filteredSectorScopes);

        const record1 = response.data.record.program

        if (response && response.data && response.data.teamsummary) {
            const record = response.data.teamsummary

            setTeamChangeType(record.team_change_type)
            setTeamChangeStatus(record.team_change_status)
            
        }


    } catch (error) { }
};


useEffect(() => {
  fetchDataTeamChange(id);
}, [id, mysectorscope,teamId]);


useEffect(() => {
  fetchData(id);
}, [id,selectedClient]);


  useEffect(() => {
    if (apiData) {
     
      const sectorscopes = apiData.sectoral_scope.split(",").map(Number);

      const filteredSectorScopes = mysectorscope.filter((sectorscope) =>
        sectorscopes.includes(sectorscope.value)
      );
      setSelectedSector(filteredSectorScopes);

    }
  }, [apiData, mysectorscope])

  useEffect(() => {
    if (monitoringPeriod.length > 0) {
      rangeForm.setFieldValue("startEndDate", [dayjs(monitoringPeriod[0]), dayjs(monitoringPeriod[1])])
    }
    else {
      rangeForm.setFieldValue("startEndDate", [null, null])
    }
  })

  useEffect(() => {
    const fetchDataTATRExpert = async () => {
      try {
        const responsetatrexpert = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=7`
        );
        setTATRExpert(responsetatrexpert.data.data.map((taexpert) => ({
          value: taexpert.id,
          label: taexpert.name,
        }))
        )

      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTATRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && taTrExpert.length > 0) {
      const taExpert = apiData.ta_expert?.split(",").map(Number);
      const filteredTaExpert = taTrExpert.filter((taexpert) =>
        taExpert.includes(taexpert.value)
      );

      setMyTaexpert(filteredTaExpert);
    }
  }, [apiData, taTrExpert]);


  useEffect(() => {
    const fetchDataTRExpert = async () => {
      try {
        const responsetatrexperts = await axios.get(
          `${get_ta_tr_experts}?program_id=${formData.program} && sectorscope_id=${selectedSector.map((value) => value.value)} && skill_id=8`
        );
   
        setTRExpert(responsetatrexperts.data.data.map((trexpert) => ({
          value: trexpert.id,
          label: trexpert.name,
        }))
        )
      } catch (error) { }
    };

    if (formData.program && selectedSector.length > 0) {
      fetchDataTRExpert();
    }
  }, [formData.program, selectedSector]);

  useEffect(() => {
    if (apiData && trExpert.length > 0) {

      const trExperts = apiData.expert_tr?.split(",").map(Number);

      const filteredTrExpert = trExpert.filter((taexpert) =>
        trExperts.includes(taexpert.value)
      );

      setMyExpertTr(filteredTrExpert);
    }
  }, [apiData, trExpert]);

  useEffect(() => {
    const fetchDataClientContactPerson = async () => {
      try {
        let payload = {
          id: selectedClient
        }
        const responseclientcontactperson = await axios.post(`${get_contact_person_url}`, payload);

        setClientContactPerson(JSON.parse(responseclientcontactperson.data.data.contact_person));
      } catch (error) { }
    };
    if (selectedClient) {
      fetchDataClientContactPerson();
    }
  }, [selectedClient]);

  const CONFIG_Token2 = {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    const fetchDataClientName = async () => {
      try {
        const responseclientname = await axios.get(`${get_client_name_url}`);
        setClientName(responseclientname.data.data);
      } catch (error) {}
    };

    fetchDataClientName();
  }, []);

  useEffect(() => {
    const fetchClientOptions = async () => {
      try {
        const response = await axios.get(`${get_client_name_url}`);
        const data = response.data;
        setClientOptions(
          data.data.map((item) => ({ value: item.id, label: item.name }))
        );
        const defaultClient = data.data
          .filter((item) => item.id === selectedClient)
          .map((client) => ({ value: client.id, label: client.name }));
        setDefaultClientName(defaultClient[0]);
      } catch (error) {
      }
    };

    fetchClientOptions();
  }, [selectedClient]);

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setClientStatus(selectedOption.value);
  };

  useEffect(() => {
    const fetchSectoralScope = async () => {
      try {
        const responsesectoralscope = await axios.get(
          `${get_sectoralscope_url}`
        );

        setMySectorScope(
          responsesectoralscope.data.data.map((scope) => ({
            value: scope.id,
            label: scope.scope,
          }))
        );
      } catch (error) {}
    };

    fetchSectoralScope();
  }, []);

  useEffect(() => {
    const fetchDataScope = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_url}`);
        setMyScope(responsescope.data.data);
      } catch (error) {}
    };

    fetchDataScope();
  }, []);

  useEffect(() => {
    const fetchDataProgram = async () => {
      try {
        const responseprogram = await axios.get(`${get_program_url}`);
        setProgram(responseprogram.data.data);
      } catch (error) {}
    };

    fetchDataProgram();
  }, []);

  useEffect(() => {
    const fetchDataSope_PA_POA = async () => {
      try {
        const responsescope = await axios.get(`${get_scope_pa_poa_url}`);

        setScope_PA_POA(responsescope.data.data);
      } catch (error) {}
    };

    fetchDataSope_PA_POA();
  }, []);

  useEffect(() => {
    const fetchDataCountry = async () => {
      try {
        const responsecountry = await axios.get(`${get_country_url}`);
        setCountry(responsecountry.data.data);
      } catch (error) {}
    };

    fetchDataCountry();
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const fetchDataTrData = async () => {
      try {
        const responsetrdata = await axios.get(`${get_trsbu_url}`);
        setTRList(responsetrdata.data.data);
      } catch (error) {}
    };

    fetchDataTrData();
  }, []);

  useEffect(() => {
    const fetchDataMeth = async () => {
      try {
        const responsemeths = await axios.get(`${get_meths_url}`);
        setMeth(responsemeths.data.data);
      } catch (error) { }
    };

    fetchDataMeth();
  }, []);

  return (
    <>
      <Header />
      <SideNavbar />
      <div className="content-wrapper bg-white">
        <div className="content">
          <div className="container-fluid bg-white">
            <div className="row pb-5 ">
              <div className="col-12 ">
                <div className="d-flex justify-content-between">
                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    Project Details
                  </h4>

                  <h4 className="mx-4 textcolorblue fw-bolder p-2 text-capitalize mt-4">
                    {role}
                  </h4>
                </div>
                <Tabs
                  defaultActiveKey="1"
                  className="mx-5"
                  activeKey={activeTab}
                  onChange={handleTabChange}
                  indicator={{ Backgroundcolor: "#07B6AF" }}
                  size="large"
                >
        


                <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0  py-2 text-center  ${activeTab == 1 ? "box_sh" : ""
                          }`}
                      >
                        <p className="font20px">Project Details</p>
                      </div>
                    }
                    key="1"
                  >
                    <form>
                      <div className="col-12 border-0 py-2 mx-auto">
                        <fieldset disabled={formEdit}>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="projectname"
                                className="form-label lightgreen fs-6"
                              >
                                Project Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Tooltip title={formData.project_name} placement="top">
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="project_name"
                                placeholder="Project Name"
                                required
                                name="project_name"
                                readOnly={true}
                                value={formData.project_name}
                              />
                              </Tooltip>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="eid"
                                className="form-label lightgreen fs-6"
                              >
                                Earthood Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control borderlightgreen"
                                id="earthood_id"
                                placeholder="Earthood Id"
                                name="earthood_id"
                                required
                                readOnly={true}
                                value={formData.earthood_id}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="clientname"
                                className="form-label lightgreen fs-6"
                              >
                                Client Name
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="client_id"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="client_id"
                                value={formData.client_id}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {clientName.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="row">

                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="contact person"
                                  className="form-label lightgreen fs-6"
                                >
                                  Client Contact Person
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="contact_person"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="contact_person"
                                  required
                                  value={formData.contact_person_id}
                                >
                                  <option value={""}>Select</option>
                                  {clientContactPerson && clientContactPerson
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program"
                                className="form-label lightgreen fs-6"
                              >
                                Program<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="program"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="program"
                                value={formData.program}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {program.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.program_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="country"
                                className="form-label lightgreen fs-6"
                                required
                              >
                                Project Country<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="country"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="country"
                                value={formData.country}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {country.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                           
                          </div>

                          <div className="row">
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope(PO/POA)"
                                className="form-label lightgreen fs-6"
                              >
                                Scope(PA/POA)
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="scope_pa"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope_pa"
                                value={formData.scope_pa}
                                disabled={true}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    scope_pa: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {scope_PA_POA.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.scope}
                                  </option>
                                ))}
                              </select>
                            </div>
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Program Id"
                                className="form-label lightgreen fs-6"
                              >
                                Program Id
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                id="program_id"
                                placeholder="Program Id"
                                required
                                name="program_id"
                                className="form-control borderlightgreen"
                                value={formData.program_id}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Implementation Fees"
                                className="form-label lightgreen fs-6"
                              >
                                Implementation Fees
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control borderlightgreen"
                                id="implemented_fees"
                                placeholder="Implementation Fees"
                                required
                                name="implemented_fees"
                                value={formData.implemented_fees}
                                readOnly={true}
                              />
                            </div>

                           

                            
                          </div>

                          <div className="row">
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <div>
                                <label
                                  htmlFor="SBU Head"
                                  className="form-label lightgreen fs-6"
                                >
                                  SBU Head
                                  <span style={{ color: "red" }}>*</span>
                                </label>

                                <select
                                  id="sbu_head"
                                  className="form-select borderlightgreen form-select-sm"
                                  aria-label="Default select example"
                                  name="sbu_head"
                                  required
                                  value={formData.sbu_head}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      sbu_head: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {trlist
                                    .filter(
                                      (option) => option.designation_id == 9
                                    )
                                    .map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Sectoral Scope
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <MultiSelect
                                options={mysectorscope}
                                value={selectedSector}
                                onChange={setSelectedSector}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Scope"
                                className="form-label lightgreen fs-6"
                              >
                                Scope<span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="scope"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="scope"
                                value={formData.scope}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {myscope.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.sector_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                           
                           
                           
                          </div>
                          <div className="row">
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Meth"
                                className="form-label lightgreen fs-6"
                              >
                                Meth

                              </label>
                              <select
                                id="meth"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth"
                                value={formData.meth}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    meth: e.target.value,
                                  })
                                }
                              >
                                <option value={""}>Select</option>
                                {meth.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="deadline Date"
                                className="form-label lightgreen fs-6"
                              >
                                Deadline Date

                              </label>

                              <DatePicker
                                id="deadlinedate"
                                className={`form-control borderlightgreen custom-disabled-date-picker`}
                                style={{ color: 'black' }}
                                disabled={formEdit}
                                format="DD/MM/YYYY"
                                value={formData.deadline_date ? dayjs(formData.deadline_date) : null}
                                // onChange={handleDeadlineDateChange}
                                disabledDate={(current) => current && current < moment().startOf('day')}
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="Monitoring Period"
                                className="form-label lightgreen fs-6"
                              >
                                Monitoring Period
                              </label>

                              <NewForm form={rangeForm} >
                                <NewForm.Item
                                  name="startEndDate"
                                >
                                  <RangePicker
                                    style={{ width: "100%" }}
                                    format={'DD/MM/YYYY'}
                                    disabled={formEdit}
                                  />
                                </NewForm.Item>
                              </NewForm>

                        
                            </div>

                            <div className="row">

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="weblink"
                                className="form-label lightgreen fs-6"
                              >
                                Weblink
                              </label>

                              <textarea
                                placeholder="Add Weblink"
                                className="form-control"
                                style={{ width: "100%" }}
                                id="weblink"
                                rows={3}
                                // disabled={tm_form_input}

                                value={weblink}
                                // onChange={handleWeblinkChange}
                              />

                            </div>
                          <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Service Agreement (F23)
                              </label>
                              <div>
                                <a href={f23name}>{f23}</a>
                              </div>
                            </div>
                          </div>
                          </div>
                        </fieldset>
                        <div className="d-flex justify-content-between  mt-5 mb-3">
                          <p className="textlightgreen fw-bold fs-6">
                            Assessment Team
                          </p>
                        </div>                      
            
                        <hr className="mb-5 lightgreen" />
                        <fieldset disabled={true}>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Team Leader
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="team_leader"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="team_leader"
                                required
                                value={formData.team_leader}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 1)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                TA Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>



                              <MultiSelect
                                options={taTrExpert}
                                value={mytaexpert}
                                onChange={setMyTaexpert}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />


                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="teamleader"
                                className="form-label lightgreen fs-6"
                              >
                                Local Expert
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="local_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="local_expert"
                                value={formData.local_expert}
                                readOnly={true}
                                required
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 6)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="financeexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Finance Expert
                              </label>

                              <select
                                id="finance_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="finance_expert"
                                value={formData.finance_expert}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 4)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="validator"
                                className="form-label lightgreen fs-6"
                              >
                                Validator/verifier
                              </label>

                              <select
                                id="validator_verifier"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="validator_verifier"
                                value={formData.validator_verifier}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 2)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="methexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Meth Expert
                              </label>

                              <select
                                id="meth_expert"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="meth_expert"
                                value={formData.meth_expert}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 5)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="taexpert"
                                className="form-label lightgreen fs-6"
                              >
                                Trainee Validator/verifier
                              </label>

                              <select
                                id="trainee_validator"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="trainee_validator"
                                value={formData.trainee_validator}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {atlist
                                  .filter((option) => option.skill_id == 3)
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>

                              <select
                                id="technical_reviewer"
                                className="form-select borderlightgreen form-select-sm"
                                aria-label="Default select example"
                                name="technical_reviewer"
                                required
                                value={formData.technical_reviewer}
                                readOnly={true}
                              >
                                <option selected value={""}>
                                  Select
                                </option>
                                {trlist
                                  .filter(
                                    (option) => option.designation_id == 7
                                  )
                                  .map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mb-3">
                              <label
                                htmlFor="technicalreviewer"
                                className="form-label lightgreen fs-6"
                              >
                                Expert to Technical Reviewer
                                <span style={{ color: "red" }}>*</span>
                              </label>



                              <MultiSelect
                                options={trExpert}
                                value={myexperttr}
                                onChange={setMyExpertTr}
                                labelledBy="Select"
                                className={formEdit ? 'multi' : ''}
                              />
                              
                            </div>

                            <div></div>
                          </div>
                        </fieldset>
                      </div>
                    </form>
                  </Tabs.TabPane>

                 
                  

                 {
                  designation_id == 8 && isTL  ?
                 
                  <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                            activeTab == 2 ? "box_sh" : ""
                          }`}
                        >
                          <p>Team Change Request</p>
                        </div>
                      }
                      key="2"
                    >

                   {
                    teamChangeStatus == 0 ?
                    <TeamChangeRequest teamId={teamId}/>
                   :
                  teamChangeType == 1 && ( teamChangeStatus ==1 || teamChangeStatus ==5 || teamChangeStatus == 3 ) ?
                   <TLTeamChange  teamId={teamId}/> 
                   :
                   teamChangeType == 2 && ( teamChangeStatus ==1 || teamChangeStatus ==5 || teamChangeStatus == 3 ) ?
                   <TLSBUTeamChange  teamId={teamId} isTL={isTL}/> 
                   :
                    <TeamChangeDisable teamId={teamId}/>
                   }

                    </Tabs.TabPane>
                    :
                    designation_id ==3 || designation_id ==4 ?
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                            activeTab == 2 ? "box_sh" : ""
                          }`}
                        >
                          <p>Team Change Request</p>
                        </div>
                      }
                      key="2"
                    >

                       {designation_id == 3 ?
                       <TMTeamChange  teamId={teamId}/> :

                       designation_id == 4 ?
                       <TMSTeamChange  teamId={teamId}/> :

                       ''
                      
                    } 

                    </Tabs.TabPane>
                    :
                    designation_id == 8 && !isTL ?
                    <Tabs.TabPane
                      tab={
                        <div
                          className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                            activeTab == 2 ? "box_sh" : ""
                          }`}
                        >
                          <p>Team Change Request</p>
                        </div>
                      }
                      key="2"
                    >

                       <TeamChangeDisable  teamId={teamId}/>

                    </Tabs.TabPane>
                    :
                    (designation_id == 9)  ?
                    <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                          activeTab == 2 ? "box_sh" : ""
                        }`}
                      >
                        <p>Team Change Request</p>
                      </div>
                    }
                    key="2"
                  >

                 {
                  teamChangeStatus == 0 ?
                  <SBUTeamChange  teamId={teamId}/>
                  :
                
                  <TeamChangeDisable  teamId={teamId}/>
                 }

                  </Tabs.TabPane>
                  :
                  ''
                  }



            {
              designation_id ==8 && isTL && teamChangeStatus >= 2?
              <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                          activeTab == 4 ? "box_sh" : ""
                        }`}
                      >
                        <p>Team Change Docs</p>
                      </div>
                    }
                    key="4"
                  >
                    <div className="col-12 border-0 bg-white">
                      <TeamChangeDocs  teamId={teamId}/>
                      
                    </div>
                </Tabs.TabPane>
                :
                 designation_id ==8 && !isTL?
                <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                          activeTab == 4 ? "box_sh" : ""
                        }`}
                      >
                        <p>Team Change Docs</p>
                      </div>
                    }
                    key="4"
                  >
                    <div className="col-12 border-0 bg-white">
                      <TeamChangeDocs  teamId={teamId}/>
                      
                    </div>
                </Tabs.TabPane>
                :
                 (designation_id ==3 || designation_id ==4) ?
                 <Tabs.TabPane
                 tab={
                   <div
                     className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                       activeTab == 4 ? "box_sh" : ""
                     }`}
                   >
                     <p>Team Change Docs</p>
                   </div>
                 }
                 key="4"
               >
                 <div className="col-12 border-0 bg-white">
                   <TeamChangeDocs  teamId={teamId}/>
                   
                 </div>
             </Tabs.TabPane>
             :
             ''
            }
               
                  <Tabs.TabPane
                    tab={
                      <div
                        className={` textlightgreen rounded-0 px-2 py-2 text-center ${
                          activeTab == 3 ? "box_sh" : ""
                        }`}
                      >
                        <p>Summary</p>
                      </div>
                    }
                    key="3"
                  >
                    <div className="col-12 border-0 bg-white">
                      <TeamChangeSummary id={projectid} teamId={teamId}/>
                      
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default TeamChangeModule;
